import store from "../../store/store";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
  updateUserType,
} from "../../store/reducers/appSlice";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import { getUniqueBrowserId } from "../utils/helper-functions";
import { detectIncognito } from "detectincognitojs";
import { LOGIN_API, OTP_API } from "../constants/central-api";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";
import { applicationWWWXRLEncodedBodyBuilder } from "../utils/body-builder";
import { submitLogToBugsnag } from "./log";

/**
 * @param { string } countryCode The corresponding Country Code
 * @param { string } phoneNumber The corresponding Phone Number
 */
export async function sendOTPCodeProfilePage(
  countryCode: string,
  phoneNumber: string
) {
  const requestInfo: RequestInfo = {
    url: `${OTP_API}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/x-www-form-urlencoded",
    },
    body: applicationWWWXRLEncodedBodyBuilder({
      contact: phoneNumber,
      country_code: countryCode,
    }),
  };

  const actionType = "SEND_OTP_CODE";
  const customMsg = "OTP Code sent Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true &&
    response?.message === "OTP sent successfully."
  ) {
    console.log(response);
    return true;
  } else {
    return false;
    console.log("OTP Code sending failed : ", response?.message);
  }
}

/**
 * @param { string } countryCode The corresponding Country Code
 * @param { string } phoneNumber The corresponding Phone Number
 */
export async function sendOTPCode(countryCode: string, phoneNumber: string) {
  const requestInfo: RequestInfo = {
    url: `${OTP_API}`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/x-www-form-urlencoded",
    },
    body: applicationWWWXRLEncodedBodyBuilder({
      contact: phoneNumber,
      country_code: countryCode,
    }),
  };

  const actionType = "SEND_OTP_CODE";
  const customMsg = "OTP Code sent Successfully.";

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;

  if (
    res &&
    res?.actionType === actionType &&
    response &&
    response?.success === true &&
    response?.message === "OTP sent successfully."
  ) {
    const currentState = store.getState().users.currentUser.verified;
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.trying,
        otp: { phone_number: phoneNumber, country_code: countryCode },
        firstPayment: "pending",
      })
    );
  } else {
    console.log("OTP Code sending failed : ", response?.message);
  }
}

/**
 * @param { string } countryCode The corresponding Country Code
 * @param { string } phoneNumber The corresponding Phone Number
 * @param { string } otpCode The OTP code entered/ being sent for verification
 */
export async function loginViaOTPCode(
  countryCode: string,
  phoneNumber: string,
  otpCode: string
) {
  const currentState = store.getState().users.currentUser.verified;
  const shopId = store.getState().cart?.shop?.id;
  var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );
  let isIncognitoWindow = false;
  try {
    await detectIncognito().then((result) => {
      console.log(
        "🌍👻 is Incognito Window ==> ",
        result.browserName,
        result.isPrivate
      );
      isIncognitoWindow = result.isPrivate;
    });

    const requestInfo: RequestInfo = {
      url: `${LOGIN_API}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded",
      },
      body: applicationWWWXRLEncodedBodyBuilder({
        contact: phoneNumber,
        country_code: countryCode,
        otp_code: otpCode,
        shop_id: shopId,
        unique_browser_id:
          isBrowserSafari && !isIncognitoWindow
            ? `${getUniqueBrowserId()}`
            : undefined,
      }),
    };

    const actionType = "LOGIN_VIA_OTP_CODE";
    const customMsg = "Logged in Successfully.";

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;

    if (
      res &&
      res?.actionType === actionType &&
      response &&
      response?.success === true &&
      response?.data?.access_token &&
      response?.data?.user
    ) {
      console.log("OTP Verification Successful");
      // set the state to verified user
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.verified,
          _: true,
          loggedInMethod: "otp",
          firstPayment: "pending",
        })
      );

      store.dispatch(setUserToken(response?.data?.access_token));

      updateStateFromShopperObject({
        ...response?.data?.user,
        bearer_token: response?.data?.access_token,
      });
      updateStateFromPaymentMethodsBlock(response?.data?.user);

      for (const segment of Object.keys(initialHiddenSegments)) {
        store.dispatch(disableFastCheckoutUISegment(segment));
      }

      store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
    } else {
      console.log("OTP Verification failed");
      // set the state to verification failed user
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.failed,
          _: false,
          firstPayment: "pending",
        })
      );
    }
  } catch (error) {
    console.log("OTP Verification failed");
    submitLogToBugsnag("error", `OTP Verification failed : ${error}`);
    // set the state to verification unverified user
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.unverified,
        _: false,
        firstPayment: "pending",
      })
    );
  }
}
