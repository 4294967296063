import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Country from "../../interfaces/Country";
import { verificationStates } from "../../store/reducers/userSlice";
import { RootState } from "../../store/store";
import { CountrySearchModal } from "../country-input-selector/CountryInputSelector";
import { validateAndGetPhoneNumber } from "./validate-phone-number";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";
import { sendOTPCode } from "../../lib/api/otp-methods";
import { applicationUserType } from "../../store/reducers/appSlice";
import CreateAdUpAccountToggleTheme1 from "../create-account/CreateAdUpAccountToggleTheme1";

interface PhoneNumberWithModalProps {
  initialPhone: string;
  initialCountry: Country;
  changeListeners?: ((event: any, meta: any) => void)[];
  showVerificationPill?: boolean;
  countries: { [index: string]: Country };
  dialCodeToCountryCodeMap: { [dialCode: string]: Country };
  disabled?: boolean;
}

const CustomPhoneField: React.FC<PhoneNumberWithModalProps> = ({
  initialCountry,
  initialPhone = "",
  changeListeners,
  countries,
  dialCodeToCountryCodeMap,
  disabled,
}) => {
  const route = window.decodeURI(useLocation().pathname);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [country, setCountry] = useState(initialCountry);

  const [showingModal, setShowingModal] = useState(false);

  // value displayed in the input field
  // i.e. with dial code
  const [value, setValue] = useState(
    initialCountry?.dialCode ? initialCountry?.dialCode + initialPhone : ""
  );
  const [flag, setFlag] = useState(
    initialCountry?.dialCode ? initialCountry?.flag : ""
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const paymentProgress = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  // check if wallet payment method is in use
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );

  // user's auth state
  const authState = useSelector((state: RootState) => state.app.auth);

  function handlePhoneNumberChange(event: ChangeEvent<HTMLInputElement>) {
    let inputString = event.target.value;
    const validatedPhoneValue = validateAndGetPhoneNumber(
      inputString,
      country,
      value
    );
    setCountry(validatedPhoneValue.country);
    setValue(validatedPhoneValue.fullNumber);
    setFlag(validatedPhoneValue?.country?.flag);
  }

  useEffect(() => {
    setCountry(initialCountry);
  }, [initialCountry]);

  useEffect(() => {
    const maxCountryCodeLength = 5;
    let codeSize = maxCountryCodeLength;

    if (value.length < codeSize) codeSize = value.length;

    while (codeSize > 1) {
      if (dialCodeToCountryCodeMap[value.substring(0, codeSize)]) {
        setCountry(
          (_) => dialCodeToCountryCodeMap[value.substring(0, codeSize)]
        );
        break;
      }
      codeSize--;
    }
  }, [value]);

  useEffect(() => {
    if (country?.dialCode && country.dialCode !== "")
      changeListeners?.forEach((listener) => {
        listener(
          {},
          {
            country: country,
            number: value.substring(country.dialCode.length),
          }
        );
      });
  }, [value]);

  useEffect(() => {
    if (country && value === "" && country?.dialCode !== "") {
      setValue(country.dialCode);
      setFlag(country?.flag);
    }
  }, [value, country]);

  return (
    <>
      {showingModal && country ? (
        <CountrySearchModal
          setCountry={setCountry}
          setPhoneNumber={(v) => {
            const validatedPhoneValue = validateAndGetPhoneNumber(
              v,
              country,
              value
            );
            setCountry(validatedPhoneValue.country);
            setValue(validatedPhoneValue.fullNumber);
            setFlag(validatedPhoneValue.country?.flag);
          }}
          hide={() => {
            setShowingModal(false);
            document.body.style.overflow = "auto";
          }}
          phoneNumberValue={value.substring(country.dialCode.length)}
          selectedCountry={country}
          currentDialCode={country.dialCode}
        />
      ) : null}
      <div className="phone-number-with-country-modal-wrapper">
        <div
          data-phone-number-with-country-modal-is-disabled={
            disabled ? "true" : "false"
          }
          className={`phone-number-with-country-modal ${
            !(
              isWalletPaymentMethodInUse &&
              authState.user === applicationUserType.NEW_USER
            ) &&
            paymentProgress === "PAYMENT_STARTED" &&
            (!validateAndGetPhoneNumber(value, country, value)?.number ||
              validateAndGetPhoneNumber(value, country, value)?.number === "")
              ? "phone-number-with-country-modal__required"
              : ""
          }`}
        >
          <div className="phone-number-with-country-inner">
            <div
              id="country-flag-holder"
              className="country-flag-holder"
              onClick={() => {
                if (!disabled) setShowingModal(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <span className="country-flag-holder-flag">{flag}</span>
            </div>
            <div className="phone-number-inputs focused">
              <div
                className={`phone-input-field-container ${
                  !(
                    isWalletPaymentMethodInUse &&
                    authState.user === applicationUserType.NEW_USER
                  ) &&
                  paymentProgress === "PAYMENT_STARTED" &&
                  (!validateAndGetPhoneNumber(value, country, value)?.number ||
                    validateAndGetPhoneNumber(value, country, value)?.number ===
                      "")
                    ? "phone-input-field-container__required"
                    : ""
                }`}
              >
                <label className="form-label-phone" htmlFor="phone-input-main">
                  {t("PhoneNumber")}
                </label>
                <input
                  className="phone-number-input-field"
                  style={{ fontFamily: "var(--main-font-secondary)" }}
                  value={value}
                  onChange={handlePhoneNumberChange}
                  type="tel"
                  name="phone"
                  id="phone-input-main"
                  disabled={disabled}
                  onFocus={() => {
                    dispatch(setInteractionsPerSessionCount());
                  }}
                ></input>
              </div>
              {verificationState?.state === verificationStates.pending && (
                <div className="verification-alert-container">
                  <div
                    className="verification-alert"
                    onClick={(event: React.MouseEvent) => {
                      sendOTPCode(
                        country.dialCode.substring(1),
                        value.substring(country.dialCode.length)
                      );
                    }}
                  >
                    {t("Verify")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* //* Create Merchant Account Prompt ----- START */}
      {(verificationState?.state === verificationStates.unverified ||
        (verificationState?.firstPayment !== "completed" &&
          route !== "/profile/account" &&
          (!verificationState?._ ||
            verificationState?.state !== verificationStates.verified))) &&
        !(
          isWalletPaymentMethodInUse &&
          authState.user === applicationUserType.NEW_USER
        ) && <CreateAdUpAccountToggleTheme1 />}
      {/* //* Create Merchant Account Prompt ----- END */}
    </>
  );
};

export default CustomPhoneField;
