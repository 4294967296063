import { t } from "i18next";
import {
  setToastMessage,
  setLastCartUrl,
  setIsAICheckout,
} from "../../store/reducers/appSlice";
import store from "../../store/store";
import { submitLogToBugsnag } from "./log";
import { getProductsByShopSlugAndSku } from "./products-methods";
import { setAllProducts, setCart } from "../../store/reducers/cartSlice";
import getImages from "../utils/get-images";

/**
 * Execute Products API & set Products state based on the route
 * @param { string } pathname
 * @return { Promise<any> | boolean }
 */
export async function productsAPICall(pathname: string): Promise<any> {
  const app_mode = pathname.split("/")[1];
  const shop_slug = pathname.split("/")[2];
  const products = pathname.split("/")[3].replace(/\[[\s\S]*?\]/g, ""); //* removing qty(s) from URL

  store.dispatch(setIsAICheckout(true));
  store.dispatch(setLastCartUrl(pathname));
  try {
    const res: any = getProductsByShopSlugAndSku(shop_slug, products);

    res.then((data: any) => {
      if (data === null) {
        console.log("Products API Error");
      } else {
        //* If no Error occurs
        setupProductsState(data?.data, app_mode, pathname);
      }
    });
  } catch (exception) {
    console.log(t("ProductsError"), exception);
    submitLogToBugsnag("error", `${t("ProductsError")} : ${exception}`);
    store.dispatch(
      setToastMessage({
        text: t("ProductsError"),
        type: "ERROR",
      })
    );
    window.open(`/${shop_slug}/products`, "_self");
    return false;
  }
}

/**
 * set the state of the store according to the products response
 * @param { any } apiResult
 */
export function setupProductsState(
  products: any,
  appMode: string,
  pathname: string
) {
  const shop = store.getState().cart?.shop;
  const merchantName = store.getState().users?.merchant?.config?.merchant_name;

  const searchLocalCart = (sku: string) => {
    let item = products.find((o: any) => o.sku === sku);
    return !isNaN(item?.quantity) ? item?.quantity : 1;
  };

  const urlProducts = pathname.split("/")[3].split(",");

  let cartTotal: number = 0.0;

  if (products[0] === "products_not_found") {
    store.dispatch(setAllProducts([]));
  } else {
    //* If no Error occurs, Products data is saved in the state.
    if (products) store.dispatch(setAllProducts(products));
  }

  if (products && products.length > 0) {
    if (appMode === "ai-fast-checkout") {
      //? Dynamically changing HTML Title of the application
      document.title = `${shop?.name} - Powered by ${merchantName} AI Checkout`;

      //* ******************** updating Cart State ******************** *//

      //* In ai-fast-checkout supported Product Types are "simple" and "variant" only
      //* In ai-fast-checkout "variable" Product Type is not supported

      const filteredProducts = products.filter(
        (i: any) => i.type !== "variable"
      );

      //* In ai-fast-checkout mode Cart is persisted
      store.dispatch(
        setCart({
          shop: shop,
          cart: filteredProducts.map((item: any) => {
            const urlQuantity = urlProducts
              .find((v: any) => v.includes(item?.sku))
              ?.split("[")[1]
              ?.split("]")[0];
            const priceNumeric =
              item?.price?.values?.[item?.price?.default]?.price?.major;
            cartTotal += priceNumeric
              ? Number(priceNumeric) *
                (urlQuantity && !isNaN(parseInt(urlQuantity))
                  ? parseInt(urlQuantity)
                  : searchLocalCart(item?.sku) ?? 1)
              : 0;
            const isProductUnavailable: boolean =
              (item?.type === "variable" &&
                (item?.variants_count <= 0 || item?.variants?.length <= 0)) ||
              (item?.type === "simple" && item?.stock <= 0);
            return {
              id: item?.id,
              sku: item?.sku,
              priceNumeric: priceNumeric ?? 0,
              priceFormatted:
                item?.price?.values?.[item?.price?.default]?.price?.formatted ??
                "0.00",
              name: item?.name,
              currency: item?.price?.default,
              image: item?.images
                ? getImages(item?.images, "medium", "only_first")
                : getImages({}, "medium", "only_first"),
              stock: item?.stock,
              disabled: isProductUnavailable,
              quantity:
                urlQuantity && !isNaN(parseInt(urlQuantity))
                  ? parseInt(urlQuantity)
                  : searchLocalCart(item?.sku) ?? 1,
            };
          }),
          total: cartTotal,
        })
      );
    } else if (appMode === "ai-full-checkout") {
      //? Dynamically changing HTML Title of the application
      document.title = `${
        products.length > 0 ? products[0]?.name + " - " : ""
      }${shop?.name} - Powered by ${merchantName}`;

      //* ******************** updating Cart State ******************** *//

      //* In ai-fast-checkout supported Product Types are "simple", "variable" and "variant"

      //* In ai-full-checkout mode Cart is NOT persisted
      //* And the Cart is handled in the src/pages/socialCommerce/SocialCommercePage.tsx
    }
  } else {
    store.dispatch(
      setCart({
        shop: shop,
        cart: [],
        total: cartTotal,
      })
    );
  }
}
