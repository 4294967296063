import { t } from "i18next";
import {
  setIPCountry,
  setToastMessage,
  setAppData,
  setAppLanguage,
  updateUserType,
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
} from "../../store/reducers/appSlice";
import { initialShop, setShop } from "../../store/reducers/cartSlice";
import {
  setDeviceCountry,
  setInitCountryCode,
  setUserToken,
  setUserVerified,
  updateMerchant,
  verificationStates,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { logOutUser } from "../state/clear-user-data";
import { submitLogToBugsnag } from "./log";
import { getUniqueBrowserId } from "../utils/helper-functions";
import { detectIncognito } from "detectincognitojs";
import { getAppInitData } from "./init-method";
import {
  setAvailablePaymentMethods,
  setAvailablePaymentSecrets,
} from "../../store/reducers/paymentSlice";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import { phoneNumberCheck } from "../../components/account-form/phone-change-listeners";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import { ENABLED_PSP_LIST } from "../constants/central-api";
import { runCacheBuster } from "../cache-buster/cache-bust-handler";

/**
 * Fetches the API token if there isn't one
 * @param { string } pathname
 * @return { Promise<any> | boolean }
 */
export async function initialAPICall(pathname: string): Promise<any> {
  if (!(await runCacheBuster())) {
    try {
      const res: any = makeInitialAPICall(pathname);
      res.then(async (data: any) => {
        if (data !== null) {
          if (res?.errors?.shop_error) {
            //* redirecting back to Root page if Shop Error occurs
            store.dispatch(setShop(initialShop));
            store.dispatch(
              setToastMessage({
                text: t("ShopError"),
                type: "ERROR",
              })
            );
            window.open(`/`, "_self");
          } else if (res?.errors?.shopper_error) {
            //* logout user if Shopper Error occurs
            store.dispatch(setUserToken(undefined));
            logOutUser();
            setupInitialState(data);
          } else {
            //* If no Error occurs
            setupInitialState(data, data?.shopper);
          }
        } else {
          submitLogToBugsnag("error", t("InitError"));
          store.dispatch(
            setToastMessage({
              text: t("InitError"),
              type: "ERROR",
            })
          );
        }
      });
    } catch (exception) {
      console.log(exception);
      submitLogToBugsnag("error", `${t("InitError")} : ${exception}`);
      store.dispatch(
        setToastMessage({
          text: t("InitError"),
          type: "ERROR",
        })
      );
      window.open(`/`, "_self");
      return false;
    }
  }
}

/**
 * @param { string } pathname
 * A key will be sent if it is passed, otherwise it will be a guest session
 */
async function makeInitialAPICall(pathname: string) {
  const shopSlugInState = store.getState().cart?.shop?.slug;
  const cartItems = store.getState().cart?.items;
  const cart: any = [];
  for (const item of cartItems) {
    cart.push({ sku: item.sku, qty: item.quantity });
  }

  let isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );

  let isIncognitoWindow = false;
  await detectIncognito().then((result) => {
    console.log(
      "🌍👻 is Incognito Window ==> ",
      result.browserName,
      result.isPrivate
    );
    isIncognitoWindow = result.isPrivate;
  });

  const app_mode = pathname.split("/")[1];
  let shop_slug: string = shopSlugInState ?? "";
  let cartString: string;
  let params: string = "";
  let uniqueBrowserId: string =
    isBrowserSafari && !isIncognitoWindow
      ? `&unique_browser_id=${getUniqueBrowserId()}`
      : "";

  console.log("APP Mode ==> ", app_mode);

  if (app_mode === "ai-fast-checkout") {
    //* AI Fast CHeckout Mode */
    shop_slug = pathname.split("/")[2];

    if (cart.length > 0) {
      cartString = JSON.stringify(cart);
      params = `?slug=${shop_slug}&cart_data=${cartString}`;
    } else {
      params = `?slug=${shop_slug}`;
    }
  } else if (app_mode === "ai-full-checkout") {
    //* AI Full CHeckout Mode */
    shop_slug = pathname.split("/")[2];

    params = `?slug=${shop_slug}`;
  } else if (pathname.split("/")[2] === "products") {
    //* Product Catalog Mode *//
    shop_slug = pathname.split("/")[1];

    params = `?slug=${shop_slug}`;
  } else if (shop_slug && shop_slug !== "") {
    params = `?slug=${shop_slug}`;
  }

  const response: any = getAppInitData(params + uniqueBrowserId);

  return response;
}

/**
 * set the state of the store according to the init response
 * @param { any } apiResult
 * @param { any | boolean } shopper If false, the user is a new user
 * @param { any | boolean } isDemoActive
 */
export function setupInitialState(
  apiResult: any,
  shopper?: any,
  isDemoActive?: boolean
) {
  console.log("Is DEMO ==> ", isDemoActive);

  const accountPhone = store.getState().users.currentUser.accountPhone;
  const currentState = store.getState().users.currentUser.verified;

  const { user_data, shop, app_data, merchant, payment_service_data } =
    apiResult;

  if (user_data?.language) {
    store.dispatch(setAppLanguage(user_data?.language));
  }

  if (user_data?.device_country) {
    store.dispatch(
      setIPCountry({ countryCode: user_data?.device_country?.countryCode })
    );
    store.dispatch(setInitCountryCode(user_data?.device_country?.countryCode));
    store.dispatch(setDeviceCountry(user_data?.device_country));
  }

  if (app_data) {
    store.dispatch(setAppData(app_data));
  }

  if (merchant) {
    store.dispatch(updateMerchant(merchant));
  }

  //* ******************** updating Shop State ******************** *//
  try {
    if (shop) {
      const defaultTheme = {
        app_layout: "0",
        header_theme: "default",
        intro_banner_theme: "default",
        product_details_theme: "default",
        user_info_theme: "default",
        payment_methods_theme: "default",
        order_summary_theme: "default",
        pay_button_theme: "default",
        footer_theme: "default",
      };

      const {
        id,
        initial,
        name,
        slug,
        currency,
        location,
        seller_id,
        is_active,
        settings,
        theme,
      } = shop;

      store.dispatch(
        setShop({
          id,
          initial,
          name,
          slug,
          currency,
          location,
          seller_id,
          is_active,
          settings,
          theme: theme ?? defaultTheme,
        })
      );
    } else {
      store.dispatch(setShop(initialShop));
    }
  } catch (exception) {
    console.log("Possible error in the shop object", exception);
    submitLogToBugsnag(
      "error",
      `${t("InitError")} : Possible error in the shop object : ${exception}`
    );
    // store.dispatch(
    //   setToastMessage({
    //     text: t("ShopError"),
    //     type: "ERROR",
    //   })
    // );
  }

  //* ******************** updating payment methods State ******************** *//

  let addedPaymentMethodsToState;
  let addedPaymentSecretsToState;

  if (payment_service_data && payment_service_data?.payment_methods) {
    //? Filter Payment Methods by Enabled PSPs in the FE
    function filterByEnabledPSP(paymentMethods: any) {
      const filteredEntries = Object.entries(paymentMethods).filter(
        ([_key, value]: any) =>
          ENABLED_PSP_LIST.includes(value.payment_service_provider?.slug)
      );
      return Object.fromEntries(filteredEntries);
    }

    addedPaymentMethodsToState = store.dispatch(
      setAvailablePaymentMethods(
        filterByEnabledPSP(payment_service_data?.payment_methods) as any
      )
    );
    addedPaymentSecretsToState = store.dispatch(
      setAvailablePaymentSecrets(payment_service_data?.payment_secrets)
    );
  }

  //* ******************** updating Shopper State ******************** *//
  try {
    if (shopper) {
      //* set the state to verified user
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.verified,
          _: true,
          loggedInMethod: "otp",
          firstPayment: "pending",
        })
      );

      updateStateFromShopperObject(shopper);

      for (const segment of Object.keys(initialHiddenSegments)) {
        store.dispatch(disableFastCheckoutUISegment(segment));
      }

      store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
    } else {
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.unverified,
          _: false,
          firstPayment: "pending",
        })
      );

      store.dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
    }
  } catch (exception) {
    console.log("Possible error in Shopper Object");
    submitLogToBugsnag(
      "error",
      `${t("InitError")} : Possible error in Shopper Object : ${exception}`
    );
    if (accountPhone.country && accountPhone.number) {
      console.log("Attempting to verify OTP again");
      phoneNumberCheck(accountPhone.country, accountPhone.number);
    } else {
      console.log("Phone number not in state > Logging Out user");
      logOutUser();
    }
  }

  //* ******************** updating payment attributes & other payment related data ******************** *//
  if (addedPaymentMethodsToState && addedPaymentSecretsToState) {
    updateStateFromPaymentMethodsBlock(shopper);
  }

  //* ******************** Sending statistics data to Analytics Endpoint ******************** *//
  //TODO: disabled analytics for now. Add later
  // submitStatisticsDataForAnalytics();
}
