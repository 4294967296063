import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import tick from "../../assets/png/blue-tick.png";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { CartSummary } from "../../components/cart-summary/CartSummary";
import { Link, useNavigate } from "react-router-dom";
import { AnimatedBlackArrowRight } from "../../assets/svg/animated-svg-components/AnimatedBlackArrowRight";
import { detectWebAuthnType } from "../../lib/webAuthn/detect-webAuthn-type";
import { checkPaymentStatus } from "../../lib/api/payment-status";
import SplashScreen from "../../components/splash-screen/SplashScreen";
import {
  setIsAICheckout,
  setLastCartUrl,
  setShowCookieConsent,
  setToastMessage,
} from "../../store/reducers/appSlice";
import Header from "../../components/header/Header";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setUserVerified } from "../../store/reducers/userSlice";
import { submitLogToBugsnag } from "../../lib/api/log";

export enum OrderStatus {
  pending = "pending",
  paid = "paid",
  processing = "processing",
  shipped = "shipped",
  completed = "completed",
  cancelled = "cancelled",
  failed = "failed",
  expired = "expired",
}

export const OrderStatusDescriptions: { [key in OrderStatus]: string } = {
  [OrderStatus.pending]:
    "the order has been submitted by the customer but is still awaiting payment",
  [OrderStatus.paid]:
    "the order payment has been received by the customer but is still awaiting processing",
  [OrderStatus.processing]:
    "the order has been confirmed and is being prepared for fulfillment",
  [OrderStatus.shipped]:
    "the order has been dispatched from the warehouse and is in transit to the customer",
  [OrderStatus.completed]:
    "the order has been successfully delivered to the customer and the transaction is complete",
  [OrderStatus.cancelled]:
    "the order has been canceled before or after shipment.",
  [OrderStatus.failed]: "the payment has been failed.",
  [OrderStatus.expired]: "the payment has been expired.",
};

export const PaymentReceivedView: React.FC = () => {
  const [result, setResult] = useState<any>({});

  const [orderStatus, setOrderStatus] = useState<OrderStatus>(
    OrderStatus.pending
  );

  const [isPaymentCompleted, setIsPaymentCompleted] = useState<Boolean>(false);

  const [isShopperAvailable, setIsShopperAvailable] = useState<Boolean>(false);

  const [isWebAuthnClosed, setIsWebAuthnClosed] = useState<Boolean>();

  const [isCreateAccountClosed, setIsCreateAccountClosed] = useState<Boolean>();

  const [isPhoneAvailable, setIsPhoneAvailable] = useState<Boolean>();

  const { t } = useTranslation();

  const isUserVerified = useSelector(
    (state: RootState) => state.users.currentUser.verified._
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const token = useSelector(
    (state: RootState) => state.users.currentUser.token
  );

  const currentPhone = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone.number
  );
  const currentCountry = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone.country.dialCode
  );

  const webauthn = useSelector(
    (state: RootState) => state.users.currentUser.webauthn
  );

  const lastCartUrl = useSelector(
    (state: RootState) => state.app.UI.lastCartUrl
  );

  const loggedInMethod = useSelector(
    (state: RootState) => state.users.currentUser.verified.loggedInMethod
  );

  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );

  const webAuthnType = detectWebAuthnType();
  const pathname = window.decodeURI(window.location.pathname);
  const cart_id = pathname.split("/")[2];
  const navigate = useNavigate();

  // if (window.opener && isPaymentOnPopUpViaIframe) {
  //   console.log(
  //     "Inside a pop-up window via iFrame -- Returning user back to the parent window"
  //   );
  //   const message = { status: "success", newUrl: pathname };
  //   window.opener.postMessage(message, "*");
  //   window.close();
  // }

  useEffect(() => {
    store.dispatch(setIsAICheckout(false));
    if (currentCountry && currentPhone) {
      setIsPhoneAvailable(true);
    }
    if (cart_id) {
      try {
        const res = checkPaymentStatus(cart_id);
        res.then(async (e) => {
          const status = e?.status as OrderStatus;
          setResult(e);
          setOrderStatus(status);
          if (status) {
            store.dispatch(setShowCookieConsent(false));
            if (e?.shopper && e?.shopper?.id) {
              setIsShopperAvailable(true);
            } else {
              setIsShopperAvailable(false);
            }
            //* need to double check API response when a payment is failed
            setIsPaymentCompleted(true);
            store.dispatch(setLastCartUrl(pathname));
            store.dispatch(
              setUserVerified({
                ...verificationState,
                firstPayment: "completed",
              })
            );
          } else {
            navigate(
              lastCartUrl && lastCartUrl !== ""
                ? lastCartUrl
                : `/${e?.shop?.slug}/products`
            );
            store.dispatch(
              setToastMessage({
                text: t("PaymentFailed"),
                type: "ERROR",
              })
            );
          }
        });
      } catch (error) {
        console.log("checkPaymentStatus API Error :", error);
        submitLogToBugsnag("error", error);
        navigate(
          lastCartUrl && lastCartUrl !== ""
            ? lastCartUrl
            : `/${result?.shop?.slug}/products`
        );
        store.dispatch(
          setToastMessage({
            text: t("PaymentFailed"),
            type: "ERROR",
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    document.title = `Thank You - Powered by ${merchantTheme?.merchant_name}`;
  }, [merchantTheme]);

  return (
    <div>
      {isPaymentCompleted && (
        <>
          <Header />
          <motion.div className="payment-received-view">
            <div className="payment-received-tick-holder">
              <LazyLoadImage
                loading="lazy"
                style={{ width: "10rem" }}
                src={tick}
                alt={"success"}
              ></LazyLoadImage>
            </div>
            <h1>{t("ThankYouText1")}</h1>
            <h2>{t("ThankYouText2")}</h2>
            <h2>
              {t("ThankYouText3")}
              <strong>{t(`${OrderStatus[orderStatus]}`)}</strong>{" "}
            </h2>
            <motion.div
              initial={{ opacity: 0, zoom: 0.6 }}
              animate={{ zoom: 1, opacity: 1 }}
              transition={{ delay: 0.45 }}
              className="payment-received-image-holder"
            >
              <h3>{t("OrderSummary")}</h3>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, zoom: 0.6 }}
              animate={{ zoom: 1, opacity: 1 }}
              transition={{ delay: 0.45 }}
              className="payment-received-image-holder"
            >
              <div
                className="payment-received-order-summary"
                /* style={{ width: "36rem" }} */
              >
                <CartSummary
                  subTotal={
                    result?.subtotal?.formatted
                      ? result?.subtotal?.formatted
                      : 0
                  }
                  shipping={
                    result?.shipping_cost?.formatted
                      ? result?.shipping_cost?.formatted
                      : 0
                  }
                  tax={
                    result?.tax_amount?.major &&
                    Number(result?.tax_amount?.major) !== 0
                      ? result?.tax_amount?.formatted
                      : "0"
                  }
                  discount={
                    result?.discount_amount?.major &&
                    Number(result?.discount_amount?.major) !== 0
                      ? result?.discount_amount?.formatted
                      : "0"
                  }
                  convenienceFee={
                    result?.convenience_fee?.major &&
                    Number(result?.convenience_fee?.major) !== 0
                      ? result?.convenience_fee?.formatted
                      : "0"
                  }
                  grandTotal={
                    result?.grand_total?.formatted
                      ? result?.grand_total?.formatted
                      : 0
                  }
                  app_mode={"thank-you"}
                />
                <h4>
                  <a
                    target="_blank"
                    href={`${merchantTheme?.how_fastcheckout_works_url}`}
                    rel="noreferrer"
                  >
                    {t("HowAdUpWorks", {
                      merchant_name: merchantTheme?.merchant_name
                        ? merchantTheme.merchant_name
                        : "",
                    })}
                  </a>
                </h4>
              </div>
            </motion.div>

            <div className="payment-received-links">
              {token && token !== "" && (
                <motion.div
                  initial={{ opacity: 0, zoom: 0.6 }}
                  animate={{ zoom: 1, opacity: 1 }}
                  transition={{ delay: 0.45 }}
                  className="payment-received-image-holder"
                >
                  <Link className="menu-button-holder" to="/profile">
                    <a className="your-account" color="white">
                      {t("YourAccount")}
                      <div
                        style={{ width: 25, marginLeft: 250, marginTop: -25 }}
                      >
                        <AnimatedBlackArrowRight />
                      </div>
                    </a>
                  </Link>
                </motion.div>
              )}
              {result?.shop && result?.shop?.name && (
                <motion.div
                  initial={{ opacity: 0, zoom: 0.6 }}
                  animate={{ zoom: 1, opacity: 1 }}
                  transition={{ delay: 0.45 }}
                  className="payment-received-image-holder"
                >
                  <Link
                    className="menu-button-holder"
                    to={`/${result?.shop?.slug}/products`}
                  >
                    <a className="your-account" color="white">
                      {result?.shop?.name}
                      <div
                        style={{ width: 25, marginLeft: 250, marginTop: -25 }}
                      >
                        <AnimatedBlackArrowRight />
                      </div>
                    </a>
                  </Link>
                </motion.div>
              )}
            </div>
            {/* //TODO: 
            //? disabled temporally until API is ready
            <div className="verify-phone-segment">
              {webAuthnType !== "NO_BIOMETRICS" &&
                !isWebAuthnClosed &&
                isUserVerified &&
                loggedInMethod !== "webauthn" && (
                  <RegisterWebAuthn
                    hideSegment={() => {
                      setIsWebAuthnClosed(true);
                    }}
                    webAuthType={webAuthnType}
                  />
                )}
              {!isCreateAccountClosed && !isShopperAvailable && (
                <CreateAccountOverlay
                  hideSegment={() => {
                    setIsCreateAccountClosed(true);
                  }}
                />
              )}
            </div> */}
          </motion.div>
        </>
      )}
      {!isPaymentCompleted && (
        <AnimatePresence>
          <SplashScreen />
        </AnimatePresence>
      )}
    </div>
  );
};
