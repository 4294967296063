const version = "v1";

let basePath = ""; // base URL of the APIs
let authUrl = ""; // redirect URL of the Shopper FrontEnd
let env: "DEV" | "STAGING" | "LIVE";

export const GOOGLE_MAPS_API_KEY = "AIzaSyDNEHfzNraau785iMc6kyFLzsg0HL5RnhE";
export const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";
export const GROWTHBOOK_CLIENT_KEY = "sdk-HHjExCOn4qRgKeq";
export const BUGSNAG_API_KEY = "e5e652c70b3d5b28c694ae9447c5f927";

if (
  window.location.href.split("/")[2].includes("localhost") ||
  process.env.NODE_ENV === "development" ||
  window.location.href.split("/")[2].includes("dev")
) {
  basePath = "https://centralapi-dev.kodeia.com/api";
  env = "DEV";
  authUrl = "https://sso-auth-dev.adup.io";
} else if (window.location.href.split("/")[2].includes("staging")) {
  basePath = "https://centralapi-staging.kodeia.com/api";
  env = "STAGING";
  authUrl = "https://sso-auth-staging.adup.io";
} else {
  basePath = "https://centralapi.adup.io/api";
  env = "LIVE";
  authUrl = "https://sso-auth.adup.io";
}

export const CACHE_BUSTER_EXECUTION_INTERVAL = 30 * 60 * 1000; //* 30 minutes
export const API_BASE = basePath;
export const APP_ENV = env;
export const AUTH_SSO_URL = authUrl;

export const CENTRAL_API_BASE_URL = `${API_BASE}/${version}/`;

//? add new PSPs here to enable those in FE
export const ENABLED_PSP_LIST = [
  "mollie",
  "buckaroo",
  "paynl",
  // "stripe",
  // "aduppay",
];

/* ----------INIT ENDPOINT----------- */
export const INIT_API = CENTRAL_API_BASE_URL + "shopper-init";

/* ----------AUTHENTICATION ENDPOINTS---------- */
export const OTP_API = CENTRAL_API_BASE_URL + "otp";
export const LOGIN_API = CENTRAL_API_BASE_URL + "login";
export const LOGOUT_API = CENTRAL_API_BASE_URL + "logout";

/* ----------PRODUCT ENDPOINTS----------- */
export const PRODUCTS_BY_SHOP_SLUG_API = (slug: string) => {
  return `${CENTRAL_API_BASE_URL}shops/${slug}/products`;
};

/* ----------ADDRESS ENDPOINTS----------- */
export const CREATE_ADDRESS_API = CENTRAL_API_BASE_URL + "address/create";
export const UPDATE_ADDRESS_API = CENTRAL_API_BASE_URL + "address";
export const DELETE_ADDRESS_API = CENTRAL_API_BASE_URL + "address/delete";

/* ----------SHIPPING ENDPOINTS---------- */
export const SHIPPING_RATES_API = CENTRAL_API_BASE_URL + "shipping";

/* ----------PROFILE ENDPOINTS----------- */
export const CREATE_PROFILE_API = CENTRAL_API_BASE_URL + "shopper/register";
export const UPDATE_PROFILE_API =
  CENTRAL_API_BASE_URL + "shopper/account/update";
export const DELETE_PROFILE_API =
  CENTRAL_API_BASE_URL + "shopper/account/delete";

/* ----------ADD-TO-CART ENDPOINT----------- */
export const ADD_TO_CART_API = CENTRAL_API_BASE_URL + "add-to-cart";

/* ----------ORDER ENDPOINTS----------- */
export const ORDER_PROCESS_API = CENTRAL_API_BASE_URL + "orders";
export const ORDER_SESSION_API = CENTRAL_API_BASE_URL + "orders/session";
export const ORDER_STATUS_API = (cart_id: string) => {
  return `${CENTRAL_API_BASE_URL}orders/status/${cart_id}`;
};
